<template>
  <el-dialog
    :title="form.id ? '修改映射' : '添加映射'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="80px"
    >
      <el-form-item
        label="业务"
        class="form-item-flex"
        prop="sceneId"
        :rules="[ { required: true, message: '业务不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.productId"
          class="mr10"
          placeholder="请选择业务"
          @change="getSceneByProcuctId"
        >
          <el-option
            v-for="(item,index) in productList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-model="form.sceneId"
          placeholder="请选择场景"
          @change="changeTitle"
        >
          <el-option
            v-for="(item,index) in sceneOptions"
            :key="index"
            :label="item.sceneName"
            :value="item.sceneId"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="映射名称"
        prop="title"
        :rules="[ { required: true, message: '标题不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.title"
        />
      </el-form-item>
      <el-form-item label="描述">
        <el-input

          v-model="form.description"
          type="textarea"
          maxlength="100"
        />
      </el-form-item>
      <el-form-item
        label="参与人员"
        prop="userIds"
        :rules="[ { required: true, message: '人员不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.userIds"
          multiple
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { mappingInfo, saveOrUpdateDataMapping } from '@/api/dataMap/dataMapProcess'
import { queryUserOptions } from '@/api/system/system'
import { getAllProduct, productSceneSelect } from '@/api/enterprise/product'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      users: [],
      productList: [],
      sceneOptions: [],
      enterpriseId: 1,
      form: {
        id: 0,
        title: '',
        description: '',
        userIds: [],
        productId: '',
        sceneId: '',
      },
    }
  },
  methods: {
    changeTitle() {
      let sceneName = ''
      let productName = ''
      this.sceneOptions.forEach(item => {
        if (this.form.sceneId === item.sceneId) {
          sceneName = item.sceneName
        }
      })
      this.productList.forEach(item => {
        if (this.form.productId === item.id) {
          productName = item.name
        }
      })
      this.form.title = `${productName}-${sceneName}`
    },
    getDetail() {
      mappingInfo(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.form.productId) {
            productSceneSelect(this.form.productId).then(res => {
              const resData = res.data
              if (resData.code === 0) {
                this.sceneOptions = resData.data
              }
            })
          }
        }
      })
    },
    getSceneByProcuctId() {
      productSceneSelect(this.form.productId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form.sceneId = ''
          this.form.title = ''
          this.sceneOptions = resData.data
        }
      })
    },
    getAllProduct() {
      getAllProduct({ productId: Number(this.form.productId) })
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.productList = resData.data
          }
        })
    },
    dialogOpen() {
      this.getEnterpriseUersSelect()
      this.getAllProduct()
      if (this.form.id) {
        this.getDetail()
      }
    },
    dialogClose() {
      this.form = {
        id: 0,
        title: '',
        description: '',
        userIds: [],
        productId: '',
        sceneId: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateDataMapping(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
        } else {
          return false
        }
      })
    },
    getEnterpriseUersSelect() {
      queryUserOptions()
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data
          }
        })
    },
  },
}
</script>

<style>
    .assest-save-form {
        padding-right: 10px;
    }

    .assest-save-form .el-icon-question {
        position: absolute;
        top: 14px;
        right: -20px;
    }
</style>
