<template>
  <div>
    <PageIntroduce
      :title="'数据活动管理'"
      :desc="'对个人信息相关的所有业务活动进行管理，活动的定义和可视化管理是数据自动化发现的关键。'"
      :tips="''"
    />
    <el-card class="box-card">
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <el-button
          v-show="savePerm"
          size="small"
          type="primary"
          icon="el-icon-s-promotion"
          @click="saveDialogShow(1)"
        >
          映射录入
        </el-button>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          :max-height="pageH - 250"
          fit
          border
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column
            prop="title"
            :show-overflow-tooltip="true"
            label="映射名称"
          />
          <el-table-column
            prop="sceneName"
            :show-overflow-tooltip="true"
            label="业务场景"
          >
            <template v-slot:="scope">
              <span>{{ scope.row.productName }}-{{ scope.row.sceneName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            :show-overflow-tooltip="true"
            label="备注"
          />
          <el-table-column
            prop="userName"
            label="发起人"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'userId'"
                :select-list.sync="selectList"
                :label="'发起人'"
                :value.sync="listQuery.userId"
                :dropdown-options="userOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="participants"
            :show-overflow-tooltip="true"
            label="参与人员"
          />
          <el-table-column
            prop="createTime"
            width="100"
            label="创建时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            prop="status"
            label="状态"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'status'"
                :select-list.sync="selectList"
                :label="'状态'"
                :value.sync="listQuery.status"
                :dropdown-options="statusOption"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <StatusBadge
                :label="statusObj[row.status]"
                :color="statusColorObj[row.status]"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            prop="activityCount"
            label="活动数"
          />
          <el-table-column
            prop="updateTime"
            label="更新时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.updateTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="userName"
            label="更新人"
          />
          <el-table-column
            label="操作"
            width="150px"
          >
            <template slot-scope="{row}">
              <el-link
                v-show="updatePerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="saveDialogShow(2,row)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="fillPerm && row.status!==3"
                :underline="false"
                class="mr10"
                type="primary"
              >
                <router-link :to="{path: '/dataActivityFill',query:{id:row.id,s:row.status}}">
                  <el-tooltip
                    content="填写"
                    placement="top"
                    effect="light"
                    el-icon-document
                  >
                    <i class="el-icon-edit-outline" />
                  </el-tooltip>
                </router-link>
              </el-link>
              <el-link
                v-if="readPerm && row.status === 3"
                :underline="false"
                class="mr10"
                type="primary"
              >
                <router-link :to="{path: '/dataActivityFill',query:{id:row.id,s:row.status}}">
                  <el-tooltip
                    content="查看"
                    placement="top"
                    effect="light"
                    el-icon-document
                  >
                    <i class="el-icon-document" />
                  </el-tooltip>
                </router-link>
              </el-link>
              <el-link
                v-if="delPerm"
                :underline="false"
                type="primary"
                @click="del(row)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <!--<pagination-->
        <!--v-show="total>0"-->
        <!--:total="total"-->
        <!--:page.sync="listQuery.page"-->
        <!--:limit.sync="listQuery.pageSize"-->
        <!--@pagination="getList"-->
        <!--/>-->
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery6'"
          :update-state="'UPDATE_LISTQUERY6'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
      </div>

    </el-card>
    <DataActivitySave
      ref="dataActivitySave"
      @handleFilter="pageRensh"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination'
import DataActivitySave from '@/views/dataIntelligent/dataActivity/DataActivitySave'
import { DeleteDataAcquisitionByIds, GetDataAcquisitionListByPage } from '@/api/dataMap/dataMapProcess'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce'
import { queryUserOptions } from '@/api/system/system'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown'

export default {
  components: {
    pagination,
    DataActivitySave,
    PageIntroduce,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },

  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      status: '',
      userId: '',
    }
    return {
      statusOption: [
        {
          label: '待录入',
          value: 1,
        },
        {
          label: '录入中',
          value: 2,
        },
        {
          label: '录入完成',
          value: 3,
        },
        {
          label: '待更新',
          value: 4,
        },
        {
          label: '更新中',
          value: 5,
        },
      ],
      statusObj: {
        1: '待录入',
        2: '录入中',
        3: '录入完成',
        4: '待更新',
        5: '更新中',

      },
      // 'primary', 'primary', 'warning', 'danger', 'success'
      statusColorObj: {
        1: '#82868b',
        2: '#0d40b2',
        3: '#3CB371',
        4: '#ff9f43',
        5: '#ea5455',
      },
      userOptions: [],
      selectList: [],
      savePerm: findBtnPerm('data-activity-2'),
      updatePerm: findBtnPerm('data-activity-3'),
      delPerm: findBtnPerm('data-activity-4'),
      fillPerm: findBtnPerm('data-activity-5'),
      readPerm: findBtnPerm('data-activity-6'),
      statusOptions: [
        {
          label: '待录入',
          value: 1,
        },
        {
          label: '录入中',
          value: 2,
        },
        {
          label: '录入完成',
          value: 3,
        },
        {
          label: '待更新',
          value: 4,
        },
        {
          label: '更新中',
          value: 4,
        },
      ],
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      series: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'data-activity-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'data-activity-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'data-activity-4')
      this.fillPerm = verifyBtnPerm(btnPerms, 'data-activity-5')
      this.readPerm = verifyBtnPerm(btnPerms, 'data-activity-6')
    })
    this.getUserSelect()
    const cacheQuery = this.$store.state.pageQuery.listQuery6
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    getUserSelect() {
      queryUserOptions()
        .then(res => {
          if (res.data.code === 0) {
            res.data.data.forEach(item => {
              this.userOptions.push({
                label: item.actualName,
                value: item.id,
              })
            })
          }
        })
    },
    del(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const ids = []
          DeleteDataAcquisitionByIds(row.id)
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    toDataTracker() {
      this.$router.push({ path: '/datamap-tracker' })
    },
    saveDialogShow(type, row) {
      this.$refs.dataActivitySave.dialogVisible = true
      if (type === 2) {
        this.$refs.dataActivitySave.form.id = Number(row.id)
      }
    },
    getList() {
      this.loading = true
      this.listQuery.status = this.listQuery.status ? this.listQuery.status : 0
      this.listQuery.userId = this.listQuery.userId ? this.listQuery.userId : 0
      GetDataAcquisitionListByPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    pageRensh() {
      this.getList()
    },
  },
}
</script>
<style>
    .cell .el-tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
        width: 100%;
    }
</style>
